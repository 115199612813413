import PropTypes from 'prop-types';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { requestCanceler } from '../../../middlewares/api';

import { toast } from '../../../utils';

import { GET_USERS } from '../../../actions';

import errorMessages from '../../../constants/errors';

import View from '../../Layout/View/View';

import NoMedia from '../../UI/NoMedia/NoMedia';
import LimitListHint from '../../UI/LimitListHint/LimitListHint';

import UsersUser from './UsersUser/UsersUser';
import UsersSearch from './UsersSearch/UsersSearch';

import './Users.scss';

class Users extends React.PureComponent {
  requestCancelers = [];

  limit = 30;

  services = [
    { key: '1', text: 'Счет Премиум (1 месяц)' },
    { key: '10003', text: 'Счет Премиум (3 месяца)' },
    { key: '10005', text: 'Счет Премиум (12 месяцев, древний: 7080 р.)' },
    { key: '10033', text: 'Счет Премиум (12 месяцев, старый: 3990 р. )' },
    { key: '10037', text: 'Счет Премиум (12 месяцев, актуальный: 4990 р. )' },
    { key: '10038', text: 'Счет Премиум (12 месяцев, устаревший: 6990 р. )' },
    { key: '10001', text: 'Счет Премиум Плюс (1 месяц)' },
    { key: '10004', text: 'Счет Премиум Плюс (3 месяца)' },
    { key: '10006', text: 'Счет Премиум Плюс (12 месяцев, новый)' },
    // { key: '10012', text: 'Счет Команда (1 месяц)' },
    // { key: '10014', text: 'Счет Команда (3 месяца)' },
    // { key: '10016', text: 'Счет Команда (12 месяцев)' },
    { key: 'divider1' },
    { key: '7', text: 'Счет Тестовый период (7 дней)' },
    { key: '36', text: 'Счет без оплаты Премиум' },
    { key: '37', text: 'Счет без оплаты Премиум Плюс' },
    { key: '2', text: 'Счет Премиум (12 месяцев, старый: 8900 р.)' },
    { key: '10002', text: 'Счет Премиум Плюс (12 месяцев, старый)' },
    { key: 'divider2' },
  ];

  componentDidMount() {
    const { location: { search }, users } = this.props;

    const isSearch = queryString.parse(search)?.search;

    if (Boolean(isSearch) || !users.docs.length) this.getUsers({ page: 1 });

    window.addEventListener('online', this.checkOnlineState);
  }

  componentDidUpdate(prevProps) {
    const { location, users } = this.props;
    const { error } = users;

    if (location.search !== prevProps.location.search) {
      this.requestCancelers.forEach((canceler) => canceler.cancelRequest());

      this.getUsers({ isSearch: true });
    }

    if (error && error !== prevProps.users.error) {
      let msg;

      if (['Network Error', 'user_not_found', 'email_incorrect', 'email_exist', 'permission_denied', 'server_error', 'admin_privileges_required', 'min_search_chars'].includes(error.message)) {
        msg = errorMessages[error.message];
      } else if (error.message === 'incorrect_data' && error.errors) {
        msg = (
          <>
            {error.errors.map((item) => (
              <div key={item.video_id}>
                {`Видео #${item.video_id} не принадлежит пользователю #${item.user_id} или не существует`}
                <br />
              </div>
            ))}
          </>
        );
      } else {
        msg = error.message;
      }

      toast('error', msg);
    }
  }

  componentWillUnmount() {
    const { onUnload } = this.props;

    this.requestCancelers.forEach((canceler) => canceler.cancelRequest());

    window.removeEventListener('online', this.checkOnlineState);

    onUnload();
  }

  getUsers = ({ page = 1, isSearch = false }) => {
    const { onGetUsers, location } = this.props;

    const requestParams = { limit: this.limit, page };

    if (location.search) {
      Object.assign(requestParams, queryString.parse(location.search));
    }

    const requestGetUsersCanceler = requestCanceler();

    this.requestCancelers.push(requestGetUsersCanceler);

    onGetUsers(requestParams, isSearch, requestGetUsersCanceler.token).catch(() => {});
  };

  loadMore = () => {
    const { users } = this.props;

    if (users.isFetching) return;

    this.getUsers({ page: users.page + 1 });
  };

  handleSearchUsers = ({ query, search_type }) => {
    const { history, location } = this.props;

    const queryStr = query ? `/users?search=${encodeURIComponent(query)}&search_type=${search_type}` : '/users';

    if (queryString.parse(location.search)?.search === query && queryString.parse(location.search)?.search_type === search_type) {
      this.requestCancelers.forEach((canceler) => canceler.cancelRequest());

      this.getUsers({ isSearch: true });
    } else {
      history.push(queryStr);
    }
  };

  checkOnlineState = () => {
    const { lastActionType } = this.props.users;

    if (navigator.onLine && lastActionType === `${GET_USERS}_REQUEST`) this.loadMore();
  };

  render() {
    const {
      auth: { user: authUser },
      users,
      onAddVideo,
      onChangeUser,
      onCreateInvoice,
      onChangePeriodDate,
      onDeletePeriod,
      onGetUserLogs,
      onRemovePeriodMigrationID,
      onUserSignIn,
      onUserUnbindOauth,
    } = this.props;

    const { error, isFetching } = users;

    return (
      <View
        errorMessage={errorMessages[error?.message]}
        isError={error && ['admin_privileges_required', 'first_request_failed'].includes(error.message)}
        isFetching={isFetching}
        title="Пользователи"
        viewClass="users"
        content={(
          <>
            <UsersSearch onSearch={this.handleSearchUsers} />
            <InfiniteScroll
              dataLength={users.docs.length}
              hasMore={users.page < users.pages}
              loader={null}
              next={this.loadMore}
              style={{ overflow: 'visible' }}
            >
              {users.docs.length > 0 && (
                <>
                  <div className="users__list">
                    {users.docs.map((user) => (
                      <div className="users__list-user" key={user._id}>
                        <UsersUser
                          user={user}
                          services={this.services}
                          onAddVideo={onAddVideo}
                          onChangeUser={onChangeUser}
                          onCreateInvoice={onCreateInvoice}
                          onDeletePeriod={onDeletePeriod}
                          onChangePeriodDate={onChangePeriodDate}
                          onGetUserLogs={onGetUserLogs}
                          onRemovePeriodMigrationID={onRemovePeriodMigrationID}
                          onUserSignIn={onUserSignIn}
                          onUserUnbindOauth={onUserUnbindOauth}
                        />
                      </div>
                    ))}
                  </div>
                  {authUser.admin_permissions.includes('support') && users.docs.length === users.limit && (
                    <LimitListHint num={users.limit} />
                  )}
                </>
              )}
              {!users.docs.length && !isFetching && (
                <div className="users__no-media">
                  <NoMedia
                    caption="Ничего не найдено"
                    icon={<FontAwesomeIcon icon={['fas', 'exclamation-circle']} />}
                  />
                </div>
              )}
            </InfiniteScroll>
          </>
        )}
      />
    );
  }
}

Users.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object,
  location: PropTypes.object,
  onAddVideo: PropTypes.func.isRequired,
  onGetUsers: PropTypes.func.isRequired,
  onCreateInvoice: PropTypes.func.isRequired,
  onChangePeriodDate: PropTypes.func.isRequired,
  onChangeUser: PropTypes.func.isRequired,
  onDeletePeriod: PropTypes.func.isRequired,
  onGetUserLogs: PropTypes.func.isRequired,
  onRemovePeriodMigrationID: PropTypes.func.isRequired,
  onUserSignIn: PropTypes.func.isRequired,
  onUserUnbindOauth: PropTypes.func.isRequired,
  onUnload: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired,
};

export default Users;
