import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  deleteCreativeStudioReplacement, getCreativeStudioReplacements, getCreativeStudioSimilarReplacements, saveCreativeStudioReplacements,
} from '../../../../../actions/creative-studio/replacements';

import { toast } from '../../../../../utils';

import { useQueryLang } from '../../../../hooks';

import sweetAlert from '../../../../HOCs/sweetAlert';
import withRequest from '../../../../HOCs/withRequest';

import View from '../../../../Layout/View/View';

import CreativeStudioNav from '../../../../Includes/CreativeStudio/CreativeStudioNav/CreativeStudioNav';
import CreativeStudioImagesSubnav from '../../../../Includes/CreativeStudio/CreativeStudioImagesSubnav/CreativeStudioImagesSubnav';
import LanguageDropdown from '../../../../Includes/LanguageDropdown/LanguageDropdown';

import Preloader from '../../../../UI/Preloader/Preloader';

import CreativeStudioImagesException from './CreativeStudioImagesException/CreativeStudioImagesException';
import errorMessages from '../../../../../constants/errors';

const CreativeStudioImagesExceptions = ({
  createCanceler, dispatch, history, location,
}) => {
  const [initialItems, setInitialItems] = useState([]);
  const [items, setItems] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [autocompleteItem, setAutocompleteItem] = useState(null);
  const [autocompleteSearch, setAutocompleteSearch] = useState('');
  const [autocompleteResult, setAutocompleteResult] = useState([]);
  const [autocompleteIsFetching, setAutocompleteIsFetching] = useState(false);

  const autocompleteTimeout = useRef(null);
  const autocompleteRequest = useRef(null);

  const { currentLang } = useQueryLang(location);

  const isInvalid = useMemo(() => !!(
    isDeleting
    || isSaving
    || _.isEqual(initialItems, items.map((item) => ({ ...item, pattern: item.pattern.trim(), replacement: item.replacement.trim() })))
    || items.some((item) => item?.pattern?.trim()?.length < 3 || item?.replacement?.trim()?.length < 3)
    || _.chain(items)
      .map((item) => item.pattern.trim().toLowerCase())
      .countBy()
      .some((count) => count > 1)
      .value()
  ), [initialItems, isDeleting, isSaving, items]);

  const handleSaveChanges = () => {
    if (isInvalid) return;

    const updateItems = items
      .filter((item) => !item.isNew)
      .filter((item) => {
        const initialItem = initialItems.find((initial) => initial._id === item._id);

        const isChanged = item.pattern !== initialItem.pattern || item.replacement !== initialItem.replacement;

        return isChanged;
      })
      .map((item) => {
        const initialItem = initialItems.find((initial) => initial._id === item._id);

        return {
          _id: item._id,
          lang: initialItem.lang,
          ...(initialItem.pattern !== item.pattern ? { pattern: item.pattern.trim() } : {}),
          ...(initialItem.replacement !== item.replacement ? { replacement: item.replacement.trim() } : {}),
        };
      });

    const createItems = items.filter((item) => item.isNew).map((item) => ({
      pattern: item.pattern.trim(),
      replacement: item.replacement.trim(),
      type: 'text_to_image',
      lang: currentLang,
    }));

    setIsSaving(true);

    const requestSaveReplacementsCanceler = createCanceler();

    dispatch(saveCreativeStudioReplacements({
      updateItems,
      createItems,
    }, requestSaveReplacementsCanceler.token))
      .then((createdItems) => {
        setInitialItems([...items.filter((item) => !item.isNew), ...createdItems]);
        setItems((prev) => [...prev.filter((item) => !item.isNew), ...createdItems]);

        setIsSaving(false);

        toast('success', 'Данные сохранены!');
      })
      .catch((error) => {
        setIsSaving(false);

        const msg = errorMessages[error?.error] || error?.error || 'Ошибка';

        toast('error', msg);
      });
  };

  const handleGetReplacements = useCallback(() => {
    setIsFetching(true);
    setItems([]);
    setInitialItems([]);

    const requestGetReplacementsCanceler = createCanceler();

    dispatch(getCreativeStudioReplacements('text_to_image', currentLang, requestGetReplacementsCanceler.token))
      .then((res) => {
        setIsFetching(false);
        setItems(res);
        setInitialItems(res);
      })
      .catch(() => {
        setIsFetching(false);
      });
  }, [currentLang]); // eslint-disable-line

  useEffect(() => {
    handleGetReplacements();
  }, [handleGetReplacements]);

  const handleChangeLang = useCallback((lang) => history.push(lang === 'ru' ? '/creative-studio/images/exceptions' : `/creative-studio/images/exceptions?lang=${lang}`), [history]);

  const handleAddReplacement = () => setItems((prev) => [
    ...prev,
    {
      _id: nanoid(), pattern: '', replacement: '', isNew: true,
    },
  ]);

  const handleDeleteReplacement = useCallback((_id) => {
    const itemForDelete = items.find((item) => item._id === _id);

    if (!itemForDelete) return;

    if (itemForDelete.isNew) {
      setItems((prev) => prev.filter((item) => item._id !== itemForDelete._id));

      return;
    }

    sweetAlert.fire({
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Да',
      icon: <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />,
      type: 'warning',
      showCancelButton: true,
      title: 'Вы уверены?',
      html: 'Вы действительно хотите удалить исключение?',
    }).then((result) => {
      if (!result.value) return;

      setIsDeleting(true);

      const requestDeleteReplacementCanceler = createCanceler();

      dispatch(deleteCreativeStudioReplacement(itemForDelete._id, requestDeleteReplacementCanceler.token))
        .then(() => {
          setIsDeleting(false);

          setInitialItems((prev) => prev.filter((item) => item._id !== itemForDelete._id));
          setItems((prev) => prev.filter((item) => item._id !== itemForDelete._id));

          toast('success', 'Исключение удалено!');
        })
        .catch(() => {
          setIsDeleting(false);

          toast('error', 'Ошибка');
        });
    });
  }, [items]); // eslint-disable-line

  const handleChangePattern = useCallback((_id, value) => {
    setItems((prev) => prev.map((item) => {
      if (item._id !== _id) return item;

      return { ...item, pattern: value.toLowerCase() };
    }));
  }, []);

  const handleChangeReplacement = useCallback((_id, value, skipAutocomplete = false) => {
    if (autocompleteTimeout.current) clearTimeout(autocompleteTimeout.current);

    if (!skipAutocomplete) {
      setAutocompleteItem(_id);
      setAutocompleteSearch(value.toLowerCase());
    }

    setItems((prev) => prev.map((item) => {
      if (item._id !== _id) return item;

      return { ...item, replacement: value.toLowerCase() };
    }));
  }, []);

  const handleAutocomplete = useCallback(() => {
    if (autocompleteTimeout.current) clearTimeout(autocompleteTimeout.current);
    if (autocompleteRequest.current) autocompleteRequest.current.cancelRequest();

    if (autocompleteSearch.length < 3) return;

    autocompleteTimeout.current = setTimeout(() => {
      setAutocompleteIsFetching(true);

      autocompleteRequest.current = createCanceler();

      dispatch(getCreativeStudioSimilarReplacements('text_to_image', currentLang, autocompleteSearch, autocompleteRequest.current.token))
        .then((res) => {
          setAutocompleteIsFetching(false);

          setAutocompleteResult([...new Set([...items.filter((item) => item.isNew && item.replacement !== autocompleteSearch).map((item) => item.replacement), ...res])]);
        })
        .catch(() => {
          setAutocompleteIsFetching(false);
        });
    }, 500);
  }, [autocompleteSearch, currentLang]); // eslint-disable-line

  const resetAutocomplete = useCallback(() => {
    if (autocompleteTimeout.current) clearTimeout(autocompleteTimeout.current);
    if (autocompleteRequest.current) autocompleteRequest.current.cancelRequest();

    setAutocompleteItem(null);
    setAutocompleteSearch('');
    setAutocompleteResult([]);
  }, []);

  const handleOutsideClick = useCallback((e) => {
    if (e.target.closest('.creative-studio__replacements-popover') || e.target.closest('.template-bunch-item .form-control')) return;

    resetAutocomplete();
  }, [resetAutocomplete]);

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  });

  useEffect(() => {
    if (autocompleteSearch.length > 0) handleAutocomplete();
  }, [autocompleteSearch, handleAutocomplete]);

  const renderLanguagesDropdown = () => (
    <div className="object-categories-language">
      <LanguageDropdown
        currentLang={currentLang}
        disabled={isFetching || isDeleting || isSaving}
        onChange={handleChangeLang}
      />
    </div>
  );

  const renderHeader = () => (
    <div className="creative-studio__header">
      <div className="creative-studio__row creative-studio__uppernav">
        <CreativeStudioNav />
        {renderLanguagesDropdown()}
      </div>
      <div className="creative-studio__subnav">
        <CreativeStudioImagesSubnav />
      </div>
    </div>
  );

  const renderItem = (item) => (
    <CreativeStudioImagesException
      key={item._id}
      autoFocus={!!item.isNew}
      item={item}
      isDisabled={isDeleting || isSaving}
      isError={items.findIndex((i) => i._id !== item._id && i.pattern.trim().toLowerCase() === item.pattern.trim().toLowerCase()) > -1 || (item.pattern.length > 0 && item.pattern.length < 3)}
      autocompleteItem={autocompleteItem}
      autocompleteIsFetching={autocompleteIsFetching}
      autocompleteResult={autocompleteResult}
      onChangePattern={handleChangePattern}
      onChangeReplacement={handleChangeReplacement}
      onDeleteReplacement={handleDeleteReplacement}
    />
  );

  const renderContent = () => isFetching ? null : ( // eslint-disable-line
    <div className="creative-studio__replacements">
      <div className="creative-studio__replacements-list">
        <div className="creative-studio__replacements-list-header">
          <div className="creative-studio__replacements-list-label">Фразы для поиска</div>
          <div className="creative-studio__replacements-list-label">Замена</div>
          <div style={{ width: '24px' }} />
        </div>
        {items.length ? items.map(renderItem) : null}
      </div>
      <Button
        className="creative-studio__replacements-add-btn"
        disabled={isDeleting || isSaving}
        variant="light"
        onClick={handleAddReplacement}
      >
        <FontAwesomeIcon icon={['far', 'plus']} />
        Добавить исключение
      </Button>
      <Button
        className="btn creative-studio__replacements-save-btn"
        disabled={isInvalid}
        variant="success"
        onClick={handleSaveChanges}
      >
        Сохранить
        {isSaving ? <Preloader small inline /> : null}
      </Button>
    </div>
  );

  return (
    <View
      isFetching={isFetching}
      header={renderHeader()}
      preloaderCaption="Загружаем..."
      title="Креативная студия"
      viewClass="creative-studio"
      content={renderContent()}
    />
  );
};

CreativeStudioImagesExceptions.propTypes = {
  createCanceler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withRequest(CreativeStudioImagesExceptions);
