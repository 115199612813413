import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Masonry } from 'masonic';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import View from '../../../../Layout/View/View';

import { languages } from '../../../../../constants/common';

import { s3Replace, toast } from '../../../../../utils';

import { getCreativeStudioAssets, updateCreativeStudioAsset } from '../../../../../actions/creative-studio/community';

import withRequest from '../../../../HOCs/withRequest';

import LanguageDropdown from '../../../../Includes/LanguageDropdown/LanguageDropdown';
import CreativeStudioNav from '../../../../Includes/CreativeStudio/CreativeStudioNav/CreativeStudioNav';
import CreativeStudioImagesSubnav from '../../../../Includes/CreativeStudio/CreativeStudioImagesSubnav/CreativeStudioImagesSubnav';

import NoMedia from '../../../../UI/NoMedia/NoMedia';
import Preloader from '../../../../UI/Preloader/Preloader';
import CheckBox from '../../../../UI/CheckBox/CheckBox';

import CreativeStudioImagesStylesStyleIcon from '../Styles/CreativeStudioImagesStylesStyleIcon/CreativeStudioImagesStylesStyleIcon';

import CreativeStudioImagesCommunityAsset from './CreativeStudioImagesCommunityAsset/CreativeStudioImagesCommunityAsset';

const ASSETS_LIMIT_PER_PAGE = 35;

const CreativeStudioImagesCommunity = ({
  createCanceler, dispatch, history, location,
}) => {
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [isCommunityFilterChanged, setIsCommunityFilterChanged] = useState(false);

  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedAssetCommunityType, setSelectedAssetCommunityType] = useState('none');
  const [updatingAsset, setUpdatingAsset] = useState(null);
  const [removedAssets, setRemovedAssets] = useState(0);

  const defaultLang = useMemo(() => Object.keys(languages)[0], []);

  const getQueryParams = useCallback(() => {
    const queryLang = queryString.parse(location.search)?.lang;
    const currentLang = queryLang && Object.keys(languages).includes(queryLang) ? queryLang : defaultLang;

    const queryCommunity = queryString.parse(location.search)?.community;

    const currentCommunity = !!(queryCommunity && queryCommunity === 'true');

    return {
      currentLang, currentCommunity,
    };
  }, [location.search, defaultLang]);

  const getAssets = useCallback(() => {
    if (isFetching) return;

    const { currentLang, currentCommunity } = getQueryParams();

    setIsCommunityFilterChanged(false);
    setIsFetching(true);

    const requestGetAssetsCanceler = createCanceler();

    dispatch(getCreativeStudioAssets({
      page,
      limit: ASSETS_LIMIT_PER_PAGE,
      lang: currentLang,
      type: 'image',
      ...currentCommunity && { community: currentCommunity },
    }, requestGetAssetsCanceler.token))
      .then((res) => {
        const newItems = res
          .filter((newItem) => list.findIndex((item) => item._id === newItem._id) < 0);

        setList([...list, ...newItems]);
        setIsLastPage(res.length < ASSETS_LIMIT_PER_PAGE);
        setPage(page + 1);
        setIsFetching(false);

        if (newItems?.length === 0) setIsRefetching(true);
      })
      .catch(() => {
        toast('error', 'Ошибка');

        setIsFetching(false);
      });
  }, [dispatch, list, isFetching, page, getQueryParams, createCanceler]);

  useEffect(() => {
    setList([]);
    setIsLastPage(false);
    setPage(1);
    setSelectedAsset(null);
    setSelectedAssetCommunityType('none');
    setIsFetching(false);

    setIsCommunityFilterChanged(true);
  }, [location.search]); // eslint-disable-line

  useEffect(() => {
    getAssets();
  }, [isCommunityFilterChanged]); // eslint-disable-line

  useEffect(() => {
    if (isRefetching && !isLastPage) {
      setIsRefetching(false);

      getAssets();
    }
  }, [isLastPage, isRefetching, getAssets]);

  const handleRefresh = () => {
    if (isFetching) return;

    setList([]);
    setIsLastPage(false);
    setPage(1);
    setSelectedAsset(null);
    setSelectedAssetCommunityType('none');
    setIsFetching(false);

    setIsCommunityFilterChanged(true);
  };

  const handleChangeQueryParams = useCallback((prop, value) => {
    const { currentLang, currentCommunity } = getQueryParams();

    const params = [];

    let result = '';

    if (prop === 'lang' && value !== defaultLang) params.push(`lang=${value}`);
    if (prop !== 'lang' && currentLang !== defaultLang) params.push(`lang=${currentLang}`);

    if (prop === 'community' && value) params.push('community=true');
    if (prop !== 'community' && currentCommunity) params.push(`community=${currentCommunity}`);

    params.forEach((param, i) => {
      result += i === 0 ? `?${param}` : `&${param}`;
    });

    history.push(`/creative-studio/images/community${result}`);
  }, [defaultLang, getQueryParams, history]);

  const handleChangeOnlyApproved = useCallback((val) => handleChangeQueryParams('community', val), [handleChangeQueryParams]);

  const handleChangeLang = useCallback((lang) => handleChangeQueryParams('lang', lang), [handleChangeQueryParams]);

  const handleUpdateAsset = () => {
    if (!selectedAsset || isFetching || updatingAsset?._id === selectedAsset._id) return;

    setUpdatingAsset(selectedAsset);

    const requestUpdateAssetCanceler = createCanceler();

    dispatch(updateCreativeStudioAsset(selectedAsset._id, {
      community: !selectedAsset.community,
      ...(!selectedAsset.community && { community_type: selectedAssetCommunityType }),
    }, requestUpdateAssetCanceler.token))
      .then((res) => {
        toast('success', selectedAsset.community ? 'Ассет удален из сообщества!' : 'Ассет добавлен в сообщество!');

        setUpdatingAsset(null);

        const { currentCommunity } = getQueryParams();

        if (currentCommunity && !res.community) {
          setSelectedAsset(null);
          setSelectedAssetCommunityType('none');

          // necessary for correct grid calculation
          setRemovedAssets(removedAssets + 1);

          setList(list.filter((item) => item._id !== res._id));
        } else {
          setList((prevList) => prevList.map((item) => {
            if (item._id !== res._id) return item;

            const newData = { ...item, ...res };

            setSelectedAsset((prevSelectedAsset) => prevSelectedAsset?._id === item._id ? newData : prevSelectedAsset); // eslint-disable-line
            setSelectedAssetCommunityType((prevSelectedAssetCommunityType) => prevSelectedAssetCommunityType === item?.community_type ? item?.community_type : prevSelectedAssetCommunityType); // eslint-disable-line

            return newData;
          }));
        }
      })
      .catch(() => {
        toast('error', 'Ошибка');

        setUpdatingAsset(null);
      });
  };

  const handleSetSelectedAsset = (asset) => {
    setSelectedAsset(asset);

    setSelectedAssetCommunityType(asset?.community_type || 'none');
  };

  const handleChangeSelectedAssetCommunityType = ({ target }) => setSelectedAssetCommunityType(target.dataset.type);

  const renderAssetsListFilters = () => {
    const { currentCommunity } = getQueryParams();

    return (
      <div className="creative-studio__comminity-filters">
        <CheckBox
          id="creative-studio-comminity-checkbox"
          className="creative-studio__comminity-checkbox"
          checked={currentCommunity}
          isFetching={isFetching}
          disabled={isFetching}
          onChange={handleChangeOnlyApproved}
        >
          Только принятые
        </CheckBox>
        <button type="button" className="btn btn-success" disabled={isFetching} onClick={handleRefresh}>
          <FontAwesomeIcon icon={['fas', 'undo-alt']} />
        </button>
      </div>
    );
  };

  const renderLanguagesDropdown = () => {
    const { currentLang } = getQueryParams();

    return (
      <div className="object-categories-language">
        <LanguageDropdown
          currentLang={currentLang}
          disabled={isFetching}
          onChange={handleChangeLang}
        />
      </div>
    );
  };

  const renderHeader = () => (
    <div className="creative-studio__header">
      <div className="creative-studio__row creative-studio__uppernav">
        <CreativeStudioNav />
        {renderLanguagesDropdown()}
      </div>
      <div className="creative-studio__subnav">
        <CreativeStudioImagesSubnav />
      </div>
    </div>
  );

  const renderAsset = ({ data }) => (
    <CreativeStudioImagesCommunityAsset
      key={data._id}
      data={data}
      isSelected={data._id === selectedAsset?._id}
      onClick={handleSetSelectedAsset}
    />
  );

  const renderList = () => { // eslint-disable-line
    const { currentCommunity } = getQueryParams();

    return (
      <div className="creative-studio__comminity-assets">
        {renderAssetsListFilters()}
        <InfiniteScroll
          dataLength={list.length}
          next={getAssets}
          hasMore={!isLastPage}
          style={{ overflow: 'visible' }}
        >
          {list.length > 0 && (
            <div className="creative-studio__comminity-assets-list">
              <Masonry
                key={`${currentCommunity ? `community-${removedAssets}` : 'all'}`}
                items={list}
                columnGutter={8}
                columnCount={6}
                overscanBy={1}
                render={renderAsset}
              />
            </div>
          )}
          {isFetching && (
            <div className="creative-studio__comminity-preloader">
              <Preloader caption="Загружаем..." />
            </div>
          )}
          {!isFetching && list.length === 0 && <NoMedia icon={<FontAwesomeIcon icon={['fas', 'info-circle']} />} caption="Список пуст" />}
        </InfiniteScroll>
      </div>
    );
  };

  const renderContent = () => (
    <div className="creative-studio__comminity-content">
      {renderList()}
      <div style={{ flex: 1 }}>
        {selectedAsset && (
          <div className="creative-studio__comminity-selected-asset">
            <button
              className="creative-studio__comminity-selected-asset-close-btn"
              disabled={isFetching}
              type="button"
              onClick={() => setSelectedAsset(null)}
            >
              <FontAwesomeIcon icon={['far', 'times']} />
            </button>
            <button
              type="button"
              className="creative-studio__comminity-selected-asset-preview-wrap"
              onClick={() => window.open(s3Replace(selectedAsset.url), '_blank')}
            >
              <div
                className="creative-studio__comminity-selected-asset-preview"
                style={{
                  paddingTop: `${(selectedAsset.info.height / selectedAsset.info.width) * 100}%`,
                  backgroundImage: `url(${s3Replace(selectedAsset.url)})`,
                }}
              />
            </button>
            <div className="creative-studio__comminity-selected-asset-prompt">
              {selectedAsset.prompt}
            </div>
            {selectedAsset.style && (
              <div className="creative-studio__comminity-selected-asset-style">
                <CreativeStudioImagesStylesStyleIcon />
                {selectedAsset.style.name}
              </div>
            )}
            <div className="creative-studio__comminity-selected-asset-types">
              <Form.Check
                checked={selectedAssetCommunityType === 'none'}
                className="form-check-custom"
                disabled={selectedAsset.community}
                data-type="none"
                id="creative-studio__community-selected-asset-type-null"
                inline
                label="Не выбрано"
                name="asset-type"
                type="radio"
                onChange={handleChangeSelectedAssetCommunityType}
              />
              <Form.Check
                checked={selectedAssetCommunityType === 'photo'}
                className="form-check-custom"
                disabled={selectedAsset.community}
                data-type="photo"
                id="creative-studio__community-selected-asset-type-photo"
                inline
                label="Фото"
                name="asset-type"
                type="radio"
                onChange={handleChangeSelectedAssetCommunityType}
              />
              <Form.Check
                checked={selectedAssetCommunityType === 'art'}
                className="form-check-custom"
                disabled={selectedAsset.community}
                data-type="art"
                id="creative-studio__community-selected-asset-type-art"
                inline
                label="Арт"
                name="asset-type"
                type="radio"
                onChange={handleChangeSelectedAssetCommunityType}
              />
            </div>
            <button
              className={`btn btn-${selectedAsset.community ? 'error' : 'success'} creative-studio__comminity-selected-asset-toggle-btn`}
              disabled={isFetching || updatingAsset?._id === selectedAsset._id || (!selectedAsset.community && selectedAssetCommunityType === 'none')}
              type="button"
              onClick={handleUpdateAsset}
            >
              {selectedAsset.community ? 'Удалить из сообщества' : 'Добавить в сообщество'}
            </button>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <View
      // errorMessage={errorMessages[companies.error?.message]}
      // isError={companies.error && ['admin_privileges_required', 'first_request_failed'].includes(companies.error.message)}
      // isFetching={companies.isFetching}
      isFetching={false}
      header={renderHeader()}
      preloaderCaption="Загружаем..."
      title="Креативная студия"
      viewClass="creative-studio"
      content={renderContent()}
    />
  );
};

CreativeStudioImagesCommunity.propTypes = {
  createCanceler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withRequest(CreativeStudioImagesCommunity);
