import {
  BULK_UPDATE_AI,
  BULK_UPDATE_AI_GROUPS,
  DELETE_AI,
  DELETE_AI_GROUP,
  GET_AI,
  GET_AI_GROUP,
  GET_AI_GROUPS_LIST,
  GET_AI_LIST,
  RESET_AI_LIST,
  UPDATE_AI,
  UPDATE_AI_GROUP,
} from '../actions';

const defaultState = {
  error: null,
  isFetching: false,
  lastActionType: null,
  currentAi: null,
  isFetchingCurrentAi: false,
  currentAiGroup: null,
  isFetchingCurrentAiGroup: false,
  isOrderingAiGroups: false,
  aiList: {
    docs: [],
  },
  groups: {
    docs: [],
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case BULK_UPDATE_AI:
      return {
        ...state,
        isFetching: false,
      };
    case BULK_UPDATE_AI_GROUPS:
      return {
        ...state,
        isFetching: false,
        isOrderingAiGroups: false,
      };
    case `${BULK_UPDATE_AI}_FAILURE`:
    case `${BULK_UPDATE_AI_GROUPS}_FAILURE`:
      return {
        ...state,
        isFetching: false,
        isOrderingAiGroups: false,
        error: action.response,
      };
    case `${BULK_UPDATE_AI}_REQUEST`:
      return {
        ...state,
        aiList: {
          docs: action.payload.map(({ _id }) => state.aiList.docs.find((ai) => ai._id === _id)),
        },
        isFetching: false,
        error: null,
      };
    case `${BULK_UPDATE_AI_GROUPS}_REQUEST`:
      return {
        ...state,
        groups: {
          docs: action.payload.map(({ _id }) => state.groups.docs.find((group) => group._id === _id)),
        },
        isFetching: false,
        isOrderingAiGroups: true,
        error: null,
      };
    case GET_AI:
      return {
        ...state,
        isFetchingCurrentAi: false,
        currentAi: action.response,
      };
    case GET_AI_GROUP:
      return {
        ...state,
        isFetchingCurrentAiGroup: false,
        currentAiGroup: action.response,
      };
    case `${GET_AI}_REQUEST`:
      return {
        ...state,
        currentAi: null,
        error: null,
        isFetchingCurrentAi: true,
        lastActionType: action.type,
      };
    case `${GET_AI_GROUP}_REQUEST`:
      return {
        ...state,
        currentAiGroup: null,
        error: null,
        isFetchingCurrentAiGroup: true,
        lastActionType: action.type,
      };
    case `${GET_AI}_FAILURE`: // DEV
      return {
        ...state,
        isFetchingCurrentAi: false,
        currentAi: {
          _id: 'asijxqweuiqwenqm,we',
          group_id: '19218390aj',
          name: 'Удаление фона с изображения',
          description: 'Оставит только объект, находящийся на переднем плане, а фон сделает прозрачным и какое-то еще очень длинное описание lorem ipsum dolor sit amet',
          action: 'background_remover',
          image_path: 'https://supa-dev-uploads.storage.yandexcloud.net/697/lcfonvtmjnuc.png',
          lang: 'ru',
          order: 1,
        },
      };
    // case `${GET_AI}_FAILURE`:
    //   return {
    //     ...state,
    //     error: action.response,
    //     isFetchingCurrentAi: false,
    //   };
    case `${GET_AI_GROUP}_FAILURE`: // DEV
      return {
        ...state,
        isFetchingCurrentAiGroup: false,
        currentAiGroup: {
          _id: '19218390aj',
          name: 'Фото',
          image_path: 'https://supa-dev-uploads.storage.yandexcloud.net/697/mqzwkuarmgyr.svg',
          lang: 'ru',
          order: 1,
        },
      };
    // case `${GET_AI_GROUP}_FAILURE`:
    //   return {
    //     ...state,
    //     error: action.response,
    //     isFetchingCurrentAiGroup: false,
    //   };
    case `${GET_AI_GROUPS_LIST}_REQUEST`:
      return {
        ...state,
        groups: {
          docs: [],
        },
        error: null,
        isFetching: true,
        lastActionType: action.type,
      };
    case `${GET_AI_LIST}_REQUEST`:
      return {
        ...state,
        aiList: {
          docs: [],
        },
        error: null,
        isFetching: true,
        lastActionType: action.type,
      };
    case `${UPDATE_AI_GROUP}_REQUEST`:
    case `${DELETE_AI_GROUP}_REQUEST`:
    case `${UPDATE_AI}_REQUEST`:
    case `${DELETE_AI}_REQUEST`:
      return {
        ...state,
        error: null,
        isFetching: true,
        lastActionType: action.type,
      };
    case GET_AI_GROUPS_LIST:
      return {
        ...state,
        ...action.response,
        groups: {
          docs: action.response,
        },
        isFetching: false,
        lastActionType: null,
      };
    case GET_AI_LIST:
      return {
        ...state,
        ...action.response,
        aiList: {
          docs: action.response,
        },
        isFetching: false,
        lastActionType: null,
      };
    case UPDATE_AI_GROUP:
      return {
        ...state,
        // groups: {
        //   docs: state.docs.map((item) => {
        //     if (item._id !== action.response._id) return item;

        //     return {
        //       ...item,
        //       ...action.response,
        //     };
        //   }),
        // },
        isFetching: false,
      };
    case UPDATE_AI:
      return {
        ...state,
        // aiList: {
        //   docs: state.docs.map((item) => {
        //     if (item._id !== action.response._id) return item;

        //     return {
        //       ...item,
        //       ...action.response,
        //     };
        //   }),
        // },
        isFetching: false,
      };
    case DELETE_AI_GROUP:
      return {
        ...state,
        groups: {
          docs: state.groups.docs.filter((doc) => doc._id !== action.payload.id),
        },
        isFetching: false,
      };
    case DELETE_AI:
      return {
        ...state,
        aiList: {
          docs: state.aiList.docs.filter((doc) => doc._id !== action.payload.id),
        },
        isFetching: false,
      };
    // case `${GET_AI_GROUPS_LIST}_FAILURE`:
    // case `${GET_AI_LIST}_FAILURE`:
    //   return {
    //     ...state,
    //     error: action?.response?.message ? action.response : { message: 'admin_privileges_required' },
    //     isFetching: false,
    //   };
    case `${GET_AI_LIST}_FAILURE`: // DEV
      return {
        ...state,
        isFetching: false,
        aiList: {
          docs: [
            {
              _id: 'jKkasdljASkljas9123jmasd',
              group_id: 'ABCD',
              name: 'Supa Magic',
              description: 'Скомпонует ваши тексты и фотографии на холсте в красивое изображение',
              action: 'supa_magic',
              image_path: 'https://supa-dev-uploads.storage.yandexcloud.net/697/xfgjoprjgqql.png',
              lang: 'ru',
              order: 0,
            },
            {
              _id: 'asijxqweuiqwenqm,we',
              group_id: '19218390aj',
              name: 'Удаление фона с изображения',
              description: 'Оставит только объект, находящийся на переднем плане, а фон сделает прозрачным и какое-то еще очень длинное описание lorem ipsum dolor sit amet',
              action: 'background_remover',
              image_path: 'https://supa-dev-uploads.storage.yandexcloud.net/697/lcfonvtmjnuc.png',
              lang: 'ru',
              order: 1,
            },
            {
              _id: '812na89j129amf6zj36,we',
              group_id: '19218390aj',
              name: 'Улучшение качества изображения',
              description: 'Увечилит разрешение изображения без потери качества',
              action: 'upscale_image',
              image_path: 'https://supa-dev-uploads.storage.yandexcloud.net/697/vfrktjvkstua.png',
              lang: 'ru',
              order: 2,
            },
            {
              _id: 'laskmq,we',
              group_id: 'omeklqw',
              name: 'Генерация текста по запросу (GPT)',
              description: 'Сгенерируйте текст на основе запроса',
              action: 'chatgpt_text_generation',
              image_path: 'https://supa-dev-uploads.storage.yandexcloud.net/697/ejuoryzkljvw.png',
              lang: 'ru',
              order: 3,
            },
            {
              _id: 'ak;lsdk;lkqwe',
              group_id: '19218390aj',
              name: 'Удаление объектов на фото',
              description: '"Бесшовно" удалит выделенный объект с фотографии',
              action: 'object_remover',
              image_path: 'https://supa-dev-uploads.storage.yandexcloud.net/697/gildgxlksupc.png',
              lang: 'ru',
              order: 4,
            },
          ],
        },
      };
    case `${GET_AI_GROUPS_LIST}_FAILURE`: // DEV
      return {
        ...state,
        isFetching: false,
        groups: {
          docs: [],
        },
      };
    case `${UPDATE_AI_GROUP}_FAILURE`:
    case `${DELETE_AI_GROUP}_FAILURE`:
    case `${UPDATE_AI}_FAILURE`:
    case `${DELETE_AI}_FAILURE`:
      return {
        ...state,
        error: action.response,
        isFetching: false,
      };
    case RESET_AI_LIST:
      return defaultState;
    default:
      return state;
  }
};
