import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

const TemplatesTemplateResources = ({ variations }) => {
  const resources = useMemo(() => {
    const stock = [];
    const ai = [];
    const titles = {};

    if (variations && variations?.length) {
      variations.forEach((variation) => {
        variation.data.slides.forEach((slide) => {
          if (slide._attribution) stock.push(slide._attribution);

          slide.objects.forEach((object) => {
            if (object._attribution && !object?._attribution_data?.ai && !object?._attribution?.includes('creative-studio')) stock.push(object._attribution); // eslint-disable-line

            if (object?._attribution?.includes('creative-studio')) {
              if (/supa-temp|supa-dev-temp/.test(object._attribution) && /supa-templates|supa-dev-templates/.test(object._attribution) === false) {
                ai.push(object.path);

                titles[object.path] = object._attribution;
              } else {
                ai.push(object._attribution);
              }
            }

            if (object?.style?.background?._attribution && !object?.style?.background?._attribution?.includes('creative-studio')) stock.push(object.style.background._attribution);

            if (object?.path?.includes('creative-studio') || object?._attribution_data?.ai) ai.push(object.path); // eslint-disable-line

            if (object?.style?.background?.path?.includes('creative-studio')) ai.push(object.style.background.path);

            if (object?.style?.background?._attribution?.includes('creative-studio')) {
              if (/supa-temp|supa-dev-temp/.test(object.style.background._attribution) && /supa-templates|supa-dev-templates/.test(object.style.background._attribution) === false) {
                ai.push(object.style.background.path);

                titles[object.style.background.path] = object.style.background._attribution;
              } else {
                ai.push(object.style.background._attribution);
              }
            }

            if (object.type === 'mockup') {
              const smartObj = object?.mockup_data?.layers?.find((layer) => layer.type === 'smart-object');

              if (smartObj) {
                const paths = [...new Set(smartObj.children.filter((item) => item?._attribution).map((item) => item._attribution))];
                const children = smartObj.children.filter((item) => item._attribution && paths.includes(item._attribution));

                children.forEach((item) => {
                  if (item._attribution?.includes('creative-studio')) {
                    if (/supa-temp|supa-dev-temp/.test(item._attribution) && /supa-templates|supa-dev-templates/.test(item._attribution) === false) {
                      ai.push(item.src);

                      titles[item.src] = item._attribution;
                    } else {
                      ai.push(item._attribution);
                    }
                  } else {
                    stock.push(item._attribution);
                  }
                });
              }
            }
          });
        });
      });
    }

    return { stock: [...new Set(stock)], ai: [...new Set(ai)], titles };
  }, [variations]);

  const renderItem = (item) => {
    const href = item;
    const title = resources.titles[item] ? `(${resources.titles[item]})` : null;

    return (
      <li key={href}><a href={href} target="_blank" rel="noreferrer" {...title ? { title } : {}}>{href}</a></li>
    );
  };

  const renderList = (name, items) => (
    items.length ? (
      <div className="template__row">
        <div className="template__label">{`${name}:`}</div>
        <div className="template__row-content template__tdr-resources">
          <ul>
            {items.map(renderItem)}
          </ul>
        </div>
      </div>
    ) : null
  );

  return (
    <>
      {renderList('Стоковые ресурсы', resources.stock)}
      {renderList('AI ресурсы', resources.ai)}
    </>
  );
};

TemplatesTemplateResources.propTypes = {
  variations: PropTypes.array,
};

export default memo(TemplatesTemplateResources);
