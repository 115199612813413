export const GET_CREATIVE_STUDIO_REPLACEMENTS = 'GET_CREATIVE_STUDIO_REPLACEMENTS';
export const GET_CREATIVE_STUDIO_SIMILAR_REPLACEMENTS = 'GET_CREATIVE_STUDIO_SIMILAR_REPLACEMENTS';
export const SAVE_CREATIVE_STUDIO_REPLACEMENTS = 'SAVE_CREATIVE_STUDIO_REPLACEMENTS';
export const DELETE_CREATIVE_STUDIO_REPLACEMENT = 'DELETE_CREATIVE_STUDIO_REPLACEMENT';

export function getCreativeStudioReplacements(type, lang, requestToken) {
  return {
    type: GET_CREATIVE_STUDIO_REPLACEMENTS,
    request: {
      method: 'get',
      url: '/admin/creative-studio/replacements',
      requestToken,
      params: { type, lang },
      apiv2: true,
    },
  };
}

export function getCreativeStudioSimilarReplacements(type, lang, search, requestToken) {
  return {
    type: GET_CREATIVE_STUDIO_SIMILAR_REPLACEMENTS,
    request: {
      method: 'get',
      url: '/admin/creative-studio/replacements/similar',
      requestToken,
      params: { type, lang, search },
      apiv2: true,
    },
  };
}

export function saveCreativeStudioReplacements(data, requestToken) {
  return {
    type: SAVE_CREATIVE_STUDIO_REPLACEMENTS,
    request: {
      method: 'post',
      url: '/admin/creative-studio/replacements',
      requestToken,
      data,
      apiv2: true,
    },
  };
}

export function deleteCreativeStudioReplacement(_id, requestToken) {
  return {
    type: DELETE_CREATIVE_STUDIO_REPLACEMENT,
    request: {
      method: 'delete',
      url: `/admin/creative-studio/replacements/${_id}`,
      requestToken,
      apiv2: true,
    },
  };
}
