import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Preloader from '../../../../../UI/Preloader/Preloader';

const CreativeStudioImagesException = ({
  autoFocus, autocompleteItem, autocompleteIsFetching, autocompleteResult, item, isDisabled, isError, onChangePattern, onChangeReplacement, onDeleteReplacement,
}) => {
  const { _id, pattern, replacement } = item;

  const handleChangePattern = (e) => !isDisabled && onChangePattern(_id, e.target.value);

  const handleChangeReplacement = (e) => !isDisabled && onChangeReplacement(_id, e.target.value);

  const handleDeleteReplacement = () => !isDisabled && onDeleteReplacement(_id);

  const renderAutocomplete = () => {
    const items = autocompleteResult.filter((autocomplete) => autocomplete !== replacement);

    return (
      <Popover className={classNames('creative-studio__replacements-popover', { 'is-scrollable': items.length > 7 })}>
        {items.length > 0 ? (
          <Popover.Content className="dropdown">
            {items.map((autocomplete) => (
              <button
                className="dropdown-item"
                key={autocomplete}
                type="button"
                onClick={() => onChangeReplacement(_id, autocomplete, true)}
              >
                {autocomplete}
              </button>
            ))}
          </Popover.Content>
        ) : null}
      </Popover>
    );
  };

  return (
    <div className="template-bunch-item">
      <div className="template-bunch-item__row">
        <Form.Control
          autoFocus={autoFocus}
          disabled={isDisabled}
          isInvalid={isError}
          placeholder="фраза"
          value={pattern}
          onChange={handleChangePattern}
        />
      </div>
      <div className="template-bunch-item__row">
        <OverlayTrigger show={!!(autocompleteItem === _id && autocompleteResult?.length > 0)} placement="bottom-start" overlay={renderAutocomplete()}>
          <Form.Control
            disabled={isDisabled}
            placeholder="замена"
            value={replacement}
            onChange={handleChangeReplacement}
          />
        </OverlayTrigger>
        {autocompleteItem === _id && autocompleteIsFetching ? <Preloader small /> : null}
      </div>
      <button
        onClick={handleDeleteReplacement}
        disabled={isDisabled}
        className="template-bunch-item__button template-bunch-item__button_delete"
        type="button"
        title="Удалить"
      >
        <FontAwesomeIcon icon={['fal', 'times']} />
      </button>
    </div>
  );
};

CreativeStudioImagesException.propTypes = {
  autoFocus: PropTypes.bool,
  autocompleteItem: PropTypes.string,
  autocompleteIsFetching: PropTypes.bool,
  autocompleteResult: PropTypes.array,
  item: PropTypes.object,
  isDisabled: PropTypes.bool.isRequired,
  isError: PropTypes.bool,
  onChangePattern: PropTypes.func.isRequired,
  onChangeReplacement: PropTypes.func.isRequired,
  onDeleteReplacement: PropTypes.func.isRequired,
};

export default memo(CreativeStudioImagesException);
